<template>
  <div class="pages__parent flex flex--expand">
    <div class="row flex flex--expand">
      <div class="col-md-2">
        <nav-bar v-show="!loggedInUser.IsTNLAdmin" />
        <admin-nav-bar v-show="loggedInUser.IsTNLAdmin === '1'" />
      </div>
      <div class="col-md-12 right-section">
        <div class="pt-3 mr-4">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/shared/MyNavbar";
import AdminNavBar from "@/components/shared/AdminNavbar";
//IsTNLAdmin
import { getData } from "../../utils/storage";

export default {
  components: {
    NavBar,
    AdminNavBar
  },
  data() {
    return {
      loggedInUser: null
    }
  },
  created() {
    this.loggedInUser = getData("userInfo");
  }
};
</script>
